<template>
    <el-tree :props="props" show-checkbox :data="menuList" node-key="menuId" ref="menuTree" :default-checked-keys="musdefaultChecked"></el-tree>
</template>

<script>
    // 菜单权限
    export default {
        created() {
            this.getAllList();
        },
        methods: {
            async init(roleId) {
                this.roleId = roleId;
                if (!this.menuList.length) {
                    await this.getAllList();
                }
                this.getList();
            },
            // 菜单权限
            getAllList() {
                let url = "/sys/role/pageQueryMenu";
                return this.$http
                    .post(url, {
                        sysMenu: {}
                    })
                    .then(({ detail }) => {
                        this.menuList = detail;
                        this.handleMenuList(this.menuList);
                    });
            },
            handleMenuList(menuList) {
                menuList.forEach(m => {
                    if (m.children && m.children.length) {
                        this.handleMenuList(m.children);
                    } else {
                        this.leafMenuIds.add(m.data.menuId);
                    }
                    m.name = m.data.name;
                    m.menuId = m.data.menuId;
                });
            },
            getCheckedNodes() {
                const checkeds = this.$refs.menuTree.getCheckedNodes();
                const halfCheckeds = this.$refs.menuTree.getHalfCheckedNodes();
                return checkeds.concat(halfCheckeds);
            },
            //获取角色权限
            getList() {
                let url = "/sys/role/queryRoleMenu";
                this.$http.post(url, this.roleId, { isRequestParam: false }).then(({ detail }) => {
                    if (detail.list && detail.list.length) {
                        const allCheckeds = detail.list.map(v => v.menuId);
                        this.musdefaultChecked = allCheckeds.filter(v => this.leafMenuIds.has(v));
                    } else {
                        this.musdefaultChecked = [];
                    }
                });
            }
        },
        data() {
            return {
                roleId: "",
                menuList: [],
                props: {
                    children: "children",
                    label: "name",
                    value: "menuId"
                },
                leafMenuIds: new Set(), // 所有叶子节点的id

                musdefaultChecked: []
            };
        }
    };
</script>
<style></style>
