export const btnMixins = {
    data() {
        return {
            btnList: []
        };
    },
    created() {
        this.btnList = this.$store.state.permissions.filter(v => v.data.pMenuId === this.btnMenuId);
    },
    methods: {
        btnexist(name) {
            const btnIndex = this.btnList.findIndex(v => v.data.menuId === name);
            return btnIndex !== -1;
        }
    }
};
