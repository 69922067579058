<template>
    <div class="add_update_account">
        <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_table" :width="global.dialogTable">
            <div>
                <el-form ref="dialogForm" :label-width="formLabelWidth">
                    <el-row v-if="dialogVisible">
                        <el-col :span="12">
                            <el-form-item label="菜单权限:" required>
                                <menu-authority ref="menu"></menu-authority>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="数据权限:">
                                <data-authority ref="data"></data-authority>
                            </el-form-item>

                            <div class="tip_warning">
                                数据权限说明：
                                <br />
                                1.用户所属机构数据权限。 用户创建后，自动获取用户所属机构本身以及所有子机构，子子机构数据权限。
                                <br />
                                2.用户角色配置的数据权限。 通过角色配置数据权限，只包含选中机构本身的数据权限，不包含配置机构除本身之外的子机构，子子机构数据权限。
                                用户在配置机构上创建的子机构，对于用户也是不可见。
                            </div>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
    import MenuAuthority from "./MenuAuthority.vue";
    import DataAuthority from "./DataAuthority.vue";
    export default {
        data() {
            return {
                title: "权限设置",
                roleId: "",
                dialogVisible: false,
                formLabelWidth: "100px"
            };
        },
        // watch: {
        //     dialogVisible(newview) {
        //         if (!newview) {
        //             this.roleId = "";
        //             this.musdefaultChecked = [];
        //             this.orgdefaultChecked = [];
        //             this.mus = false;
        //             this.orgs = false;
        //         }
        //     }
        // },
        methods: {
            init(option) {
                this.dialogVisible = true;
                if (option) {
                    this.roleId = option.roleId;
                    this.$nextTick(() => {
                        this.$refs.menu.init(option.roleId);
                        this.$refs.data.init(option.roleId);
                    });
                }
            },
            cancel() {
                this.dialogVisible = false;
                console.log("cancel");
            },
            save() {
                // let url = "/sys/role/roleJurisdictionSetting";
                let url = "/sys/role/adjustRoleOrgAndMenu";
                const sysRoleMenuList = this.$refs.menu.getCheckedNodes();
                const sysRoleOrgList = this.$refs.data.getCheckedNodes();
                if (!sysRoleMenuList.length) {
                    this.$message({
                        type: "warning",
                        message: "请选择菜单权限"
                    });
                    return false;
                }
                this.$http
                    .post(url, {
                        sysRoleMenuList: sysRoleMenuList.map(v => {
                            return {
                                roleId: this.roleId,
                                menuId: v.menuId,
                                appId: "iWastes"
                            };
                        }),
                        sysRoleOrgList: sysRoleOrgList.map(v => {
                            return {
                                roleId: this.roleId,
                                orgId: v.data.orgId,
                                appId: "iWastes"
                            };
                        })
                    })
                    .then(res => {
                        this.$message({
                            type: "success",
                            message: res.msg
                        });
                        this.dialogVisible = false;
                        this.$emit("updateList");
                    });
            }
        },
        components: { MenuAuthority, DataAuthority }
    };
</script>
<style scoped>
    .tip_warning {
        color: red;
        font-size: 13px;
    }
</style>
