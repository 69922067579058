<template>
    <el-tree
        :props="jsprops"
        show-checkbox
        node-key="num"
        ref="orgTree"
        :default-checked-keys="orgdefaultChecked"
        :default-expanded-keys="orgdefaultOpen"
        lazy
        :check-strictly="true"
        render-after-expand
        :load="loadNode"
        :render-content="renderTreeNode"
    ></el-tree>
</template>

<script>
    // 数据权限
    export default {
        methods: {
            init(roleId) {
                this.roleId = roleId;
                this.getzzList();
            },
            getCheckedNodes() {
                return this.$refs.orgTree.getCheckedNodes();
            },
            // 数据权限
            loadNode(node, resolve) {
                let id = "";
                if (node.data && node.data.type === 0) {
                    id = node.data.data.orgId;
                }
                this.$http
                    .post(
                        "/sys/org/queryOrgTree",
                        {
                            orgId: id,
                            entryP: 0
                        },
                        { isRequestParam: false }
                    )
                    .then(res => {
                        const childNodes = res.detail[0].children;
                        childNodes.forEach(v => {
                            v.num = v.data.num;
                        });
                        resolve(childNodes);
                    });
            },
            renderTreeNode(h, { data }) {
                const { data: val } = data;
                return <span>{val.orgNa}</span>;
            },
            // 获取数据权限
            getzzList() {
                let url = "/sys/role/queryRoleOrg";
                this.$http.post(url, this.roleId, { isRequestParam: false }).then(({ detail }) => {
                    if (detail && detail.length) {
                        const checkedKeys = detail.map(v => v.num);
                        let openKeys = new Set();
                        detail.forEach(v => {
                            const list = v.path.split("/");
                            list.forEach(v => {
                                if (v && !isNaN(v) && !checkedKeys.includes(Number(v))) {
                                    openKeys.add(Number(v));
                                }
                            });
                        });
                        this.orgdefaultOpen = [...openKeys];
                        this.orgdefaultChecked = checkedKeys;
                    } else {
                        this.orgdefaultChecked = [];
                    }
                });
            }
        },
        data() {
            return {
                // 组织机构默认选中
                orgdefaultChecked: [],
                orgdefaultOpen: [],
                jsprops: {
                    children: "children"
                },

                roleId: ""
            };
        }
    };
</script>
<style></style>
