<template>
    <div class="add_update_role">
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :rules="rules" :label-width="formLabelWidth">
                    <el-form-item label="角色名称:" prop="roleNa">
                        <el-input v-model="diaform.roleNa"></el-input>
                    </el-form-item>
                    <el-form-item label="所属机构:" prop="orgNa" v-if="dialogVisible">
                        <div @click="selectInstitutions">
                            <el-input placeholder="请选择" v-model="diaform.orgNa" readonly></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="角色说明:" prop="descp">
                        <el-input v-model="diaform.descp" type="textarea" :rows="4" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择机构"></institutions-tree>
    </div>
</template>
<script>
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    export default {
        data() {
            return {
                type: "",
                title: "",
                diaform: {
                    roleNa: "",
                    descp: "",
                    orgNa: "",
                    orgId: ""
                },
                rules: {
                    roleNa: [
                        {
                            required: true,
                            message: "请输入角色名称",
                            trigger: "blur"
                        }
                    ],
                    orgNa: [
                        {
                            required: true,
                            message: "请选择所属机构",
                            trigger: "change"
                        }
                    ]
                },
                dialogVisible: false,
                formLabelWidth: "100px"
            };
        },
        watch: {
            type: function (val) {
                let t = "";
                switch (val) {
                    case "add":
                        t = "新增角色";
                        break;
                    case "update":
                        t = "修改角色";
                        break;
                    default:
                        t = "新增角色";
                }
                this.title = t;
            },
            dialogVisible(newview) {
                if (!newview) {
                    this.resetForm("dialogForm");
                    this.diaform.orgId = "";
                }
            }
        },
        components: {
            institutionsTree
        },
        methods: {
            init(option, type) {
                this.type = type ? type : "add";
                this.dialogVisible = true;
                if (option) {
                    this.$nextTick(() => {
                        this.diaform = JSON.parse(JSON.stringify(option));
                    });
                }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            cancel() {
                this.dialogVisible = false;
                console.log("cancel");
            },
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            // 上级机构查询
            addInstitutions(data) {
                console.log(data);
                this.diaform.orgId = data.orgId;
                this.diaform.orgNa = data.orgNa;
            },
            save() {
                this.$refs.dialogForm.validate(valid => {
                    if (valid) {
                        let url = "/sys/role/";
                        if (this.type == "add") {
                            url = url + "addRole";
                        } else {
                            url = url + "updateRole";
                        }
                        this.$http
                            .post(url, {
                                roleId: this.diaform.roleId,
                                roleNa: this.diaform.roleNa,
                                descp: this.diaform.descp,
                                orgId: this.diaform.orgId
                            })
                            .then(res => {
                                this.$message({
                                    type: "success",
                                    message: res.msg
                                });
                                this.dialogVisible = false;
                                this.$emit("updateList");
                            });
                    } else {
                        return false;
                    }
                });
            }
        }
    };
</script>
